import React from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import styled from "styled-components"
import {
  Typography,
  Layout,
  SEO,
  Grid,
  GridCell,
  Footer,
  Wrapper,
} from "../components"
import { breakpoints } from "../styles/breakpoints"
import "katex/dist/katex.min.css"
// import "prismjs/themes/prism-solarizedlight.css"
import "prismjs/themes/prism.css"

const HeroImage = styled(Img)`
  height: 514px;
  width: 100%;

  @media (max-width: ${breakpoints.PHONE}) {
    height: 240px;
  }
`

export default function Template({ data }) {
  const { mdx } = data
  const { body, frontmatter } = mdx

  return (
    <Layout page="/blog">
      <SEO
        title={frontmatter.title}
        description={frontmatter.excerpt}
        socialImage={frontmatter.image.childImageSharp.fluid.src}
        path={frontmatter.path}
      />

      <HeroImage
        objectFit="cover"
        objectPosition="50% 50%"
        fluid={frontmatter.image.childImageSharp.fluid}
        alt="Hero"
      />

      <Wrapper width={884}>
        <Grid>
          <GridCell
            span={12}
            css={`
              margin: 11px 0;
            `}
          >
            <Typography use="body2">
              <Link to="/blog/">Blog</Link> > {frontmatter.title}
            </Typography>
          </GridCell>

          <GridCell span={12}>
            <Typography tag="h1" use="headline2">
              {frontmatter.title}
            </Typography>
            <Typography tag="h2" use="subtitle1">
              {frontmatter.date}
            </Typography>
            {frontmatter.author && (
              <Typography tag="h4" use="subtitle2">
                by {frontmatter.author}
              </Typography>
            )}
          </GridCell>

          <GridCell
            span={12}
            css={`
              /* Markdown styles. */

              img {
                max-width: 100%;
              }

              word-wrap: break-word;
              color: var(--text-color);
            `}
          >
            <MDXRenderer>{body}</MDXRenderer>
          </GridCell>
        </Grid>
      </Wrapper>

      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        path
        title
        author
        excerpt
        date(formatString: "DD MMMM, YYYY")
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
